/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useReducer, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FlexContainer,
  SelectButton,
  Sidebar,
  Text,
  theme,
} from "@nordcloud/gnui";
import { DetailWrapper, ValueWrapper } from "~/components/Details/styles";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools/text";
import { StyledMultiSelect } from "~/views/events/components/DateRangeSelector/styles";
import { convertToMinutes, scheduleSchema } from "~/views/plans/utils";
import { useGenerateDatesFromCron } from "../../hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { useUpdatePlan } from "../../hooks/useUpdatePlan/useUpdatePlan";
import { ScheduleCron } from "../../PlanCreate/components/PlanCreateWizard/PlanScheduleForms/ScheduleCron";
import { RadioCheckbox } from "../../PlanCreate/components/PlanCreateWizard/PlanScheduleForms/SelectSchedule";
import { isFormValid } from "../../PlanCreate/components/PlanCreateWizard/PlanScheduleForms/utils";
import { TimeUnits } from "../../PlanCreate/components/PlanCreateWizard/types";
import { Schedule } from "../../types";
import { usePlan } from "../PlanProvider";

export enum FormField {
  SCHEDULE_CRON = "scheduleCron",
  SCHEDULE_TIMEZONE = "scheduleTimezone",
  SCHEDULE_OFFSET = "scheduleOffset",
  SCHEDULE_OFFSET_UNIT = "scheduleOffsetUnit",
}

export type FormData = {
  [FormField.SCHEDULE_CRON]?: string;
  [FormField.SCHEDULE_TIMEZONE]: string;
  [FormField.SCHEDULE_OFFSET]?: number;
  [FormField.SCHEDULE_OFFSET_UNIT]: TimeUnits;
};

type State = {
  cronNextDates: string[];
  isSidebarOpen: boolean;
};

export function CronDetails() {
  const [scheduleType, setScheduleType] = useState<Schedule>(Schedule.repeated);
  const { plan } = usePlan();

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({
      ...data,
      ...partialData,
    }),
    {
      cronNextDates: [],
      isSidebarOpen: false,
    }
  );

  const formMethods = useForm({
    resolver: zodResolver(scheduleSchema),
    defaultValues: {
      [FormField.SCHEDULE_CRON]: plan?.scheduleCron ?? "",
      [FormField.SCHEDULE_TIMEZONE]: plan?.scheduleTimezone ?? "",
      [FormField.SCHEDULE_OFFSET]: plan?.scheduleOffset ?? 0,
      [FormField.SCHEDULE_OFFSET_UNIT]: TimeUnits.minutes,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;

  const success = () => {
    updateState({ isSidebarOpen: false });
    showSuccess(generateActionSuccessText("Plan")()("updated")());
  };

  const [updatePlan, loading] = useUpdatePlan({
    onSuccess: success,
  });

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    updateState: updateState,
  });

  const onSubmit = async (data: FormData) => {
    if (!isFormValid(errors)) {
      setError(FormField.SCHEDULE_CRON, {
        message: errors.scheduleCron?.message,
      });

      return;
    }

    const offsetInMinutes = convertToMinutes(
      data.scheduleOffset?.toString() ?? "",
      data.scheduleOffsetUnit ?? TimeUnits.minutes
    );

    updatePlan({
      id: plan?.id ?? "",
      scheduleCron: data.scheduleCron,
      scheduleTimezone: data.scheduleTimezone,
      scheduleOffset: offsetInMinutes,
    });
  };

  const data = useMemo(() => {
    return {
      cron_expression: plan?.scheduleCron ?? "",
      location: plan?.scheduleTimezone ?? "",
      offset_in_minutes: plan?.scheduleOffset,
    };
  }, [plan?.scheduleCron, plan?.scheduleTimezone, plan?.scheduleOffset]);

  useEffect(() => {
    generateDatesFromCron(data);
  }, [data, generateDatesFromCron]);

  const handleClick = () => {
    updateState({ isSidebarOpen: true });
    clearErrors(FormField.SCHEDULE_CRON);
  };

  return (
    <>
      <DetailWrapper data-testid="Next execution">
        <FlexContainer alignItems="flex-start" direction="column">
          <Text
            tag="div"
            size="sm"
            color={theme.color.text.text02}
            css={{ textDecoration: "none" }}
          >
            Next execution
          </Text>
          <ValueWrapper editable isEmpty={false}>
            <div data-testid="Plan Cron">
              {state.cronNextDates[0] ?? "Time not defined"}
            </div>
          </ValueWrapper>
        </FlexContainer>
        <Button
          aria-label="edit button"
          icon="edit"
          size="md"
          severity="low"
          ml={theme.spacing.spacing02}
          onClick={handleClick}
          css={{ visibility: "hidden" }}
        />
      </DetailWrapper>
      <Sidebar
        isOpen={state.isSidebarOpen}
        onClick={() => updateState({ isSidebarOpen: false })}
        title="Schedule Plan"
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box boxStyle="lightGrey">
              <RadioCheckbox
                name={scheduleKind[0].name}
                label={scheduleKind[0].label}
                scope={scheduleKind[0].scope}
                onScopeChange={setScheduleType}
                disabled={scheduleKind[0].disabled}
              />
              <StyledMultiSelect
                size="small"
                css={{
                  margin: `0 0 ${theme.spacing.spacing04} ${theme.spacing.spacing07}`,
                }}
              >
                {scheduleButtons.map((type) => {
                  return (
                    <SelectButton
                      key={type.value}
                      name={type.value}
                      value={type.value}
                      labelText={type.label}
                      isActive={scheduleType === type.value}
                      onClick={() => setScheduleType(type.value)}
                      disabled={type.value === Schedule.runOnce}
                    />
                  );
                })}
              </StyledMultiSelect>
              <ScheduleCron planData={plan} nextDates={state.cronNextDates} />
              <RadioCheckbox
                name={scheduleKind[1].name}
                label={scheduleKind[1].label}
                scope={scheduleKind[1].scope}
                onScopeChange={setScheduleType}
                disabled={scheduleKind[1].disabled}
              />
            </Box>
            <Button
              mt={theme.spacing.spacing04}
              type="submit"
              disabled={loading}
              initialState={loading ? "loading" : ""}
            >
              Apply
            </Button>
          </form>
        </FormProvider>
      </Sidebar>
    </>
  );
}

const scheduleKind = [
  {
    name: "Schedule options",
    label: "Specify the time or intervals to run the plan.",
    scope: Schedule.repeated,
    disabled: false,
  },
  {
    name: "Run Manually",
    label: "You can run plan later.",
    scope: Schedule.runManually,
    disabled: true,
  },
];

const scheduleButtons = [
  {
    label: "Repeated",
    value: Schedule.repeated,
  },
  {
    label: "Run Once on Time",
    value: Schedule.runOnce,
  },
];
