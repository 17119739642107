/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import { Box, BrickLoader } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ErrorWrap } from "~/components/Error";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { ROUTES } from "~/routing/routes";
import { useGetPlan } from "../hooks";
import { Actions } from "./components/Actions";
import { PlanInfo } from "./components/PlanInfo";
import { PlanActionTypeExtended, PlanCtxProvider } from "./PlanProvider";
import { Tabs } from "./Tabs/Tabs";
import { PlanDetailsParams } from "./types";

export function PlanDetails() {
  const { planId } = useParams<PlanDetailsParams>();

  const { plan, loading, error } = useGetPlan({
    planId,
  });

  const [planAction, setPlanAction] = useState<PlanActionTypeExtended>();
  const [planActions, setPlanActions] = useState<PlanActionTypeExtended[]>(
    plan?.planActions ?? []
  );

  const title = plan?.name ?? planId;
  const isPlanActive = plan?.enabled;

  const breadcrumbs = [
    { label: "Home", uri: "/" },
    { label: "Plans", uri: ROUTES.plans.index },
    { label: title, uri: "" },
  ];

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <PlanCtxProvider
        value={{
          plan,
          planActions,
          setPlanActions: setPlanActions,
          planAction: planAction,
          setPlanAction: setPlanAction,
        }}
      >
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <BreadcrumbsBox title={title} customBreadcrumbs={breadcrumbs}>
                <Actions
                  planId={planId}
                  planName={title}
                  isPlanActive={isPlanActive}
                  size="large"
                />
              </BreadcrumbsBox>
            </Col>
          </Row>
          <Box spacing="spacing02">
            <Row>
              <PlanInfo />
            </Row>
          </Box>
          <Tabs />
        </LoaderWrap>
      </PlanCtxProvider>
    </ErrorWrap>
  );
}
