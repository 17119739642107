/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { Else, If, Then } from "react-if";
import { isNonEmpty } from "~/tools";
import { usePlanWizard } from "../PlanProvider";
import { StepState } from "../types";
import { ActionsView, AddAction, NoActionView } from "./components";
import { StepStateCtxProvider } from "./StepProvider";

type Props = {
  nextStep?: () => void;
};

export function PlanSettingsForm({ nextStep }: Props) {
  const { planData, orderOfSelectedAction } = usePlanWizard();

  const [stepState, updateStepState] = useReducer(
    (data: StepState, partialData: Partial<StepState>) => ({
      ...data,
      ...partialData,
    }),
    {
      actions: planData?.plan_settings?.planActions,
      selectedAction:
        planData?.plan_settings?.planActions?.[orderOfSelectedAction ?? 0] ??
        undefined,
    }
  );

  return (
    <StepStateCtxProvider value={{ stepState, updateStepState }}>
      <If condition={isNonEmpty(stepState.actions)}>
        <Then>
          <ActionsView nextStep={nextStep} />
        </Then>
        <Else>
          <NoActionView
            addAction={<AddAction buttonName="Add First Action" />}
          />
        </Else>
      </If>
    </StepStateCtxProvider>
  );
}
