/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "@nordcloud/gnui";
import { useGenerateDatesFromCron } from "~/views/plans/hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { PlanData, PlanField } from "../constants";
import { units } from "../PlanSettingsForms/components/ActionSettingsForm/options";
import { CronScheduleState, FormField, TimeUnits } from "../types";
import { adjustInvalidCronMessage, convertToMinutes } from "./utils";

type Props = {
  state: CronScheduleState;
  updateState: Dispatch<Partial<CronScheduleState>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronDelayUnitDropDown({
  state,
  updateState,
  setPlanData,
}: Props) {
  const { control, setError, clearErrors } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    updateState: updateState,
  });

  const handleCronDates = async (timeUnit: TimeUnits) => {
    const offsetInMinutes = convertToMinutes(state.offset, timeUnit);

    const result = await generateDatesFromCron({
      cron_expression: state.cronExpression,
      location: state.timezone,
      offset_in_minutes: offsetInMinutes,
    });

    const invalidCronMessage =
      result.data?.generateDatesFromCron.validationMsg ||
      result.data?.generateDatesFromCron.error;

    if (invalidCronMessage) {
      setError(FormField.SCHEDULE_CRON, {
        message: adjustInvalidCronMessage(invalidCronMessage),
      });

      return;
    }

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_OFFSET]: offsetInMinutes,
        [FormField.SCHEDULE_OFFSET_UNIT]: timeUnit,
      },
    }));

    clearErrors(FormField.SCHEDULE_CRON);
  };

  return (
    <Controller
      name={FormField.SCHEDULE_OFFSET_UNIT}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Dropdown
          name={FormField.SCHEDULE_TIMEZONE}
          data-testid="dropdown-select-offset-unit"
          options={units}
          onChange={(delayUnit: string) => {
            onChange(delayUnit);

            if (isTimeUnits(delayUnit)) {
              updateState({ offsetUnit: delayUnit });
            }

            const isScheduleDataValid =
              isTimeUnits(delayUnit) &&
              state.timezone &&
              state.cronExpression &&
              state.offset !== 0;

            if (isScheduleDataValid) {
              handleCronDates(delayUnit);
            }
          }}
          value={state.offsetUnit || value}
          minNumOfOptionsToShowSearchBox={10}
        />
      )}
    />
  );
}

function isTimeUnits(value: string): value is TimeUnits {
  return value in TimeUnits;
}
