/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "@nordcloud/gnui";
import { timezones } from "~/constants/timezones";
import { useGenerateDatesFromCron } from "~/views/plans/hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { PlanData, PlanField } from "../constants";
import { CronScheduleState, FormField } from "../types";
import { convertToMinutes, isFormValid } from "./utils";

type Props = {
  state: CronScheduleState;
  updateState: Dispatch<Partial<CronScheduleState>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronTimezoneDropDown({
  state,
  updateState,
  setPlanData,
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    updateState: updateState,
  });

  const handleCronDates = async (value: string) => {
    const offsetInMinutes = convertToMinutes(state.offset, state.offsetUnit);

    await generateDatesFromCron({
      cron_expression: state.cronExpression,
      location: value,
      offset_in_minutes: offsetInMinutes,
    });

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_TIMEZONE]: value,
      },
    }));
  };

  const handleChange = (tz: string) => {
    updateState({ timezone: tz });

    if (isFormValid(errors) && state.cronExpression) {
      handleCronDates(tz);
    }
  };

  return (
    <Controller
      name={FormField.SCHEDULE_TIMEZONE}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Dropdown
          name="Select timezone"
          data-testid="dropdown-select-timezone"
          options={timezones}
          onChange={(tz: string) => {
            onChange(tz);
            handleChange(tz);
          }}
          value={state.timezone || value}
          minNumOfOptionsToShowSearchBox={10}
        />
      )}
    />
  );
}
