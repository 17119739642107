/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction, useReducer } from "react";
import { useFormContext } from "react-hook-form";
import { theme, FlexContainer, Label, Text } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { PlanExtended } from "~/views/plans/PlanDetails/PlanProvider";
import { PlanData } from "../constants";
import { CronScheduleState, FormField, TimeUnits } from "../types";
import { CronDelayInput } from "./CronDelayInput";
import { CronDelayUnitDropDown } from "./CronDelayUnitDropdown";
import { CronExpressionInput } from "./CronExpressionInput";
import { CronTimezoneDropDown } from "./CronTimezoneDropdown";
import { CronNextDates } from "./NextDateCron";
import { ReadableCron } from "./ReadableCron";

type Props = {
  planData?: Partial<PlanExtended>;
  planDataForm?: PlanData;
  nextDates?: string[];
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function ScheduleCron({
  planData,
  planDataForm,
  nextDates,
  setPlanData,
}: Props) {
  const [state, updateState] = useReducer(
    (data: CronScheduleState, partialData: Partial<CronScheduleState>) => ({
      ...data,
      ...partialData,
    }),
    {
      timezone:
        planDataForm?.schedule_plan?.scheduleTimezone ??
        planData?.scheduleTimezone ??
        "",
      cronExpression:
        planDataForm?.schedule_plan?.scheduleCron ??
        planData?.scheduleCron ??
        "",
      offset:
        planDataForm?.schedule_plan?.scheduleOffset ??
        planData?.scheduleOffset ??
        0,
      offsetUnit:
        planDataForm?.schedule_plan?.scheduleOffsetUnit ?? TimeUnits.minutes,
      cronNextDates: nextDates ?? [],
    }
  );

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormGroup error={errors[FormField.SCHEDULE_TIMEZONE]}>
        <Label css={{ marginBottom: 0 }} name="Plan Timezone" required />
        <Text
          mb={theme.spacing.spacing02}
          size="sm"
          color={theme.color.text.text02}
        >
          By default local timezone will be displayed. You can change it to:
        </Text>
        <CronTimezoneDropDown
          state={state}
          updateState={updateState}
          setPlanData={setPlanData}
        />
      </FormGroup>

      <FormGroup>
        <Label name="Cron Window Start" htmlFor="scheduleCron" required />
        <FlexContainer
          mb={theme.spacing.spacing01}
          gap={theme.spacing.spacing03}
          alignContent="flex-start"
        >
          <div css={{ minWidth: "12rem" }}>
            <CronExpressionInput
              state={state}
              updateState={updateState}
              setPlanData={setPlanData}
            />
          </div>
          <ReadableCron cronExpression={state.cronExpression} />
        </FlexContainer>
        <div css={{ height: "2rem" }}>
          <CronNextDates dates={state.cronNextDates} />
          <Text size="sm" color={theme.colors.danger} tag="div">
            <>{errors[FormField.SCHEDULE_CRON]?.message}</>
          </Text>
        </div>
      </FormGroup>

      <FormGroup error={errors[FormField.SCHEDULE_OFFSET]}>
        <Label css={{ marginBottom: 0 }} name="Delay" />
        <Text
          mb={theme.spacing.spacing02}
          size="sm"
          color={theme.color.text.text02}
        >
          Choose a time to delay the plan execution after the scheduled cron.
        </Text>
        <FlexContainer
          mb={theme.spacing.spacing04}
          gap={theme.spacing.spacing03}
        >
          <div css={{ width: "5rem" }}>
            <CronDelayInput
              state={state}
              updateState={updateState}
              setPlanData={setPlanData}
            />
          </div>
          <div>
            <CronDelayUnitDropDown
              state={state}
              updateState={updateState}
              setPlanData={setPlanData}
            />
          </div>
        </FlexContainer>
      </FormGroup>
    </>
  );
}
