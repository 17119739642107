/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Else, If, Then } from "react-if";
import {
  ExtendedTooltip,
  Label,
  MultipleSelect,
  SelectButton,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ExecutionPolicy } from "~/generated/graphql";
import { getFirstItem, noop } from "~/tools";
import {
  defaultActionSettingsValues,
  PlanField,
  SystemActionNames,
} from "../../../constants";
import { FormData } from "../../../formConfig";
import { usePlanWizard } from "../../../PlanProvider";
import { FormField } from "../../../types";
import { useStepState } from "../../StepProvider";
import { executionPolicy, resourceOperation, skipWindow } from "./options";
import { WindowDuration } from "./WindowDuration";

type Props = {
  nextStep?: () => void;
  setTab: (key: number) => void;
};

export function ActionSettingsForm({ nextStep, setTab }: Props) {
  const { stepState, updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  const firstAction = getFirstItem(stepState.actions ?? []);
  const isFirstAction = firstAction.listId === stepState.selectedAction?.listId;

  const formMethods = useForm<FormData>({
    defaultValues: defaultActionSettingsValues,
  });
  const { control, reset, handleSubmit, setValue } = formMethods;

  useEffect(() => {
    reset({
      [FormField.UNIT]: stepState.selectedAction?.unit,
      [FormField.EXECUTION_POLICY]: stepState.selectedAction?.executionPolicy,
      [FormField.RUN_IN_SEQUENCE]: stepState.selectedAction?.runInSequence,
      [FormField.WINDOW_DURATION]: stepState.selectedAction?.windowDuration,
      [FormField.SKIP_WINDOW]: stepState.selectedAction?.skipWindow,
    });
  }, [reset, stepState.selectedAction]);

  const handleChange = (
    name: FormField,
    onChange: (value: string | boolean) => void,
    value: string | boolean
  ) => {
    const stepActions = stepState?.actions ?? [];
    const actionIndex =
      stepActions?.findIndex(
        (action) => action.listId === stepState.selectedAction?.listId
      ) ?? -1;
    const updatedAction = {
      ...stepActions?.[actionIndex],
      ...{ [name]: value },
    };
    const actions = [
      ...stepActions?.slice(0, actionIndex),
      updatedAction,
      ...stepActions?.slice(actionIndex + 1),
    ];
    updateStepState({
      actions: actions,
      selectedAction: updatedAction,
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(actions ?? [])],
      },
    }));
    onChange(value);
  };

  const submit = () => {
    if (stepState.selectedAction?.notificationGroups) {
      setTab(2);
    } else {
      nextStep?.();
    }
  };

  const executionPolicyFirstAction = executionPolicy.map((policy) => ({
    ...policy,
    disabled:
      policy.value === ExecutionPolicy.SuccessOrApproval
        ? true
        : policy.disabled,
  }));

  const modifiedExecutionPolicy = isFirstAction
    ? executionPolicyFirstAction
    : executionPolicy;

  //  set default value for execution policy when action is on first position
  useEffect(() => {
    if (
      isFirstAction &&
      firstAction[FormField.EXECUTION_POLICY] ===
        ExecutionPolicy.SuccessOrApproval
    ) {
      setValue(FormField.EXECUTION_POLICY, ExecutionPolicy.Anyway);
      handleChange(FormField.EXECUTION_POLICY, noop, ExecutionPolicy.Anyway);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstAction]);

  return (
    <FormProvider {...formMethods}>
      <form
        id="planSettingsForm"
        onSubmit={handleSubmit(submit)}
        css={{ minHeight: "13rem" }}
      >
        <If
          condition={
            stepState.selectedAction?.actionName === SystemActionNames.DELAY
          }
        >
          <Then>
            <Label css={{ marginBottom: "0" }} htmlFor="delay" name="Delay" />
            <Text mb={theme.spacing.spacing02} size="sm">
              Next action will start after this time has passed.
            </Text>
            <WindowDuration control={control} handleChange={handleChange} />
          </Then>
          <Else>
            <Label name="Action Execution Policy" css={{ marginBottom: 0 }} />
            <Text mb={theme.spacing.spacing02} size="sm" tag="div">
              Specify the behavior of this action based on the status of the
              previous action.
            </Text>
            <Controller
              control={control}
              name={FormField.EXECUTION_POLICY}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultipleSelect size="small">
                    {modifiedExecutionPolicy.map((policy) => (
                      <ExtendedTooltip
                        caption={policy.description}
                        key={policy.value}
                        position="start"
                      >
                        <SelectButton
                          key={policy.value}
                          name={policy.value}
                          value={policy.value}
                          labelText={policy.label}
                          isActive={value === policy.value}
                          disabled={policy.disabled}
                          onClick={() =>
                            handleChange(
                              FormField.EXECUTION_POLICY,
                              onChange,
                              policy.value
                            )
                          }
                        />
                      </ExtendedTooltip>
                    ))}
                  </MultipleSelect>
                );
              }}
            />
            <Spacer height={theme.spacing.spacing04} />
            <Label name="Resource Operation" css={{ marginBottom: 0 }} />
            <Text mb={theme.spacing.spacing02} size="sm">
              Select the resource process below.
            </Text>
            <Controller
              control={control}
              name={FormField.RUN_IN_SEQUENCE}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultipleSelect size="small">
                    {resourceOperation.map((operation) => (
                      <ExtendedTooltip
                        caption={operation.description}
                        position="start"
                        key={operation.label}
                      >
                        <SelectButton
                          key={operation.label}
                          name={operation.label}
                          value={operation.label}
                          labelText={operation.label}
                          isActive={value === operation.value}
                          onClick={() =>
                            handleChange(
                              FormField.RUN_IN_SEQUENCE,
                              onChange,
                              operation.value
                            )
                          }
                        />
                      </ExtendedTooltip>
                    ))}
                  </MultipleSelect>
                );
              }}
            />
            <Spacer height={theme.spacing.spacing04} />
            <Label
              css={{ marginBottom: "0" }}
              htmlFor="activeWindowDuration"
              name="Action Window Duration"
            />
            <Text mb={theme.spacing.spacing02} size="sm" tag="div">
              Enter the amount of time for each action. Any resources that are
              not up to date during this time period will not be executed.
            </Text>
            <WindowDuration control={control} handleChange={handleChange} />
            <Label name="Skip window" />
            <Controller
              control={control}
              name={FormField.SKIP_WINDOW}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultipleSelect size="small">
                    {skipWindow.map((skipWindowOption) => (
                      <ExtendedTooltip
                        caption={skipWindowOption.description}
                        position="start"
                        key={skipWindowOption.value}
                      >
                        <SelectButton
                          key={skipWindowOption.value}
                          name={skipWindowOption.value}
                          value={skipWindowOption.value}
                          labelText={skipWindowOption.label}
                          isActive={value === skipWindowOption.value}
                          onClick={() =>
                            handleChange(
                              FormField.SKIP_WINDOW,
                              onChange,
                              skipWindowOption.value
                            )
                          }
                        />
                      </ExtendedTooltip>
                    ))}
                  </MultipleSelect>
                );
              }}
            />
          </Else>
        </If>
      </form>
    </FormProvider>
  );
}
