/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import {
  Action,
  Plan,
  PlanAction,
  PlanActionType,
  SystemAction,
} from "~/generated/graphql";
import { createCtx } from "~/tools";
import {
  NotificationGroupItem,
  TimeUnits,
} from "../PlanCreate/components/PlanCreateWizard/types";

type ActionType = Action & { version?: string };

export type PlanExtended = Omit<Plan, "planAction"> & {
  planAction?: PlanActionTypeExtended;
};

export type PlanActionTypeExtended = Omit<
  PlanAction,
  "action" | "notificationGroups"
> & {
  action?: ActionType | SystemAction;
  actionType?: PlanActionType;
  resourceGroupIds?: string[];
  notificationGroups?: NotificationGroupItem[] | null;
  unit?: TimeUnits;
};

type Props = {
  plan?: Partial<PlanExtended>;
  planAction?: PlanActionTypeExtended;
  planActions?: PlanActionTypeExtended[];
  setPlanAction?: (planAction?: PlanActionTypeExtended) => void;
  setPlanActions?: Dispatch<SetStateAction<PlanActionTypeExtended[]>>;
};

export const [usePlan, PlanCtxProvider] = createCtx<Props>();
